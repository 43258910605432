import React from "react";
import { graphql } from "gatsby";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import BreadcrumbList from "../../components/seo/BreadcrumbList";

const KaufOderMiete = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader h1Title="Kauf oder Miete? Die Grundsatzfrage" showCalc={false} />
            <Article>
                <p>
                    Kauf oder Miete - das ist, wenn es ums Wohnen geht, sozusagen die Gretchenfrage. Zunächst gilt es,
                    zwei andere grundlegende Fragen zu beantworten: Bist du erstens tatsächlich dazu bereit, dich für
                    den Immobilienkauf zu verschulden, oder vielleicht doch eher ein:e Freund:in der Flexibilität, die
                    das Mieten mit sich bringt? Und zweitens: Was wäre für dich leistbar, wenn du deine aktuelle bzw.
                    eine potenzielle Monatsmiete in einen Immobilienkredit investieren würdest?
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Was ist der bessere Deal?</h2>
                <p>
                    Der hohe Aufwand und die vielen versteckten Kosten beim Kauf einer Immobilie schrecken viele
                    Menschen berechtigterweise ab. Und wer hat schon in jungen Jahren mehrere zehntausend Euro
                    angespart? Aber nicht jede:r Eigentümer:in hat geerbt oder im Lotto gewonnen.
                </p>
                <p>
                    Viele Banken sind durchaus bereit, dir mit einem sehr kleinen Ersparnis bereits einen Kredit zu
                    geben, wenn du sie davon überzeugen kannst, dass du in der Lage bist, die monatliche Rate zu
                    bezahlen. Hast du also ein sicheres Einkommen und ein wenig Erspartes, ist ein Kauf realistischer,
                    als du vielleicht denkst. Außerdem kann man sich auch bei Krediten Unterstützung holen und eine
                    Bürgschaft finden, die im Falle, dass du nicht zahlen kannst, für dich einspringt. Ein sicheres
                    Einkommen ist allerdings auch wichtig, wenn du Miete zahlst. Für unverbindliche Erstinformationen
                    über die Kosten eines{" "}
                    <Link to="/artikel/kredit/" target="_blank" rel="noreferrer noopener">
                        Kredits
                    </Link>{" "}
                    eignet sich übrigens unser{" "}
                    <Link to="/dienstleistungen/kreditrechner/" target="_blank" rel="noreferrer noopener">
                        Kreditrechner
                    </Link>
                    .
                </p>
                <p>
                    Beachte: Es ist weder mit dem Kaufpreis einer Immobilie noch mit der Miete derselben getan! Jeder
                    Kauf ist mit Nebenkosten und späteren Fixkosten neben der Kreditrate verbunden. Und jede Miete mit
                    einer Ablöse, einer Provision und wechselnden Strom- und Gaskosten. Beachte das unbedingt, wenn du
                    vor der Entscheidung Kauf oder Miete stehst.
                </p>
                <hr />

                <h2>Es muss nicht sofort das Traumhaus sein</h2>
                <p>
                    Wenn du vor der Entscheidung stehst, ob Kauf oder Miete das Richtige ist, bedenke auch Folgendes:
                    Bei den steigenden Immobilienpreisen kann es sich durchaus lohnen, in Immobilien zu investieren,
                    auch wenn sie noch nicht zu hundert Prozent deinen Vorstellungen entsprechen. Die Möglichkeit, sie
                    später wieder zu verkaufen, damit einen kleinen Gewinn zu erzielen und wieder einen Kredit
                    aufzunehmen, besteht immer.
                </p>
                <p>
                    Wenn du nach fünf Jahren in deiner kleinen Eigentumswohnung genug hast und wieder ausziehen
                    möchtest, kannst du sie wieder verkaufen und bekommst dein investiertes Geld zumindest zurück, wenn
                    nicht sogar mehr. Vielleicht hast du bis dahin auch mehr Geld gespart, ein größeres Einkommen und
                    kannst dir einen höheren Kredit leisten, um in teurere Immobilien zu investieren. Es muss also nicht
                    zwangsläufig die Entscheidung Kauf oder Miete gefällt werden - du kannst auch mieten, um später zu
                    kaufen. Hier muss man allerdings auch einräumen, dass die Zukunft nicht vorhersehbar ist und die
                    Preise irgendwann wieder stark fallen können. Deshalb sollte man darauf achten, wie sich
                    unterschiedliche Faktoren auf den Immobilienpreis auswirken können und sich vor dem Kauf immer gut
                    informieren und von vertrauenswürdigen Personen beraten lassen.
                </p>
                <hr />

                <h2>Was ist dir im Leben wichtiger?</h2>
                <p>
                    Egal wo du lebst, es sollte immer auch zu deiner Lebenssituation und deinen Vorstellungen passen.
                    Wenn du mietest, bist du einerseits freier, andererseits auch abhängig. Wenn du kaufst, ist es aber
                    genauso. Beides hat viele Vor- und Nachteile. Deine jetzige Miete ist genauso monatlich zu bezahlen
                    wie deine Kreditrate und die Betriebskosten es wären. Allerdings kannst du dich von einer
                    Mietwohnung leichter trennen und deshalb eher den Wohnort wechseln. Bei der Gestaltung deines
                    Wohnraumes hast du in einer Eigentumswohnung hingegen alle Freiheiten, in einer Mietwohnung muss
                    vieles vorher mit dem:der Vermieter:in abgeklärt werden und einfach eine Wand einzureißen, wird
                    einem wohl niemand erlauben.
                </p>
                <p>
                    Möchtest du selbst flexibel sein, verreisen, vielleicht einmal in eine andere Stadt ziehen, oder
                    lieber dein eigenes fixes Zuhause haben, das du jederzeit flexibel umgestalten kannst? Ein Kredit
                    läuft oft über mehrere Jahrzehnte und ist eine große Verantwortung. Aber am Ende zahlt er sich auch
                    aus, denn dir bleibt für das Geld immer dein eigenes Haus oder deine Wohnung. Wenn du mietest,
                    zahlst du monatlich für etwas, das am Ende nicht dir gehört.
                </p>
                <hr />

                <h2>Keine Eile, aber auch keine Angst</h2>
                <p>
                    Egal, schließlich für Kauf oder Miete entscheidest: triff keine voreiligen Entschlüsse. Jeder Kauf
                    muss vorher gut überlegt sein. Aber nur zu zögern, weil du Angst vor Schulden hast, sollte dich
                    nicht abhalten. Schulden zu haben ist nichts Schlimmes, wenn du weißt, wie du damit umgehen kannst.
                    Hast du einen Job und vielleicht sogar Aufstiegschancen, stehen auch die Chancen gut, dass du mit
                    einem Kredit gut umgehen kannst. Ob Ka uf oder Miete, das Wichtigste ist in beiden Fällen die
                    richtige Beratung.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
            </Article>

            <BreadcrumbList page={"kaufOderMiete"}></BreadcrumbList>
            <ArticleStructuredData page={"kaufOderMiete"} heading={"Kauf oder Miete? Die Grundsatzfrage"} />
        </Layout>
    );
};

export default KaufOderMiete;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["header", "footer", "page.artikel.kauf-oder-miete", "mortgageCalc"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
